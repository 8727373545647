jQuery(document).ready( function() {

    var postcode = jQuery('.bb-input-field input').val();
    var sortby = "";
    var filterby = [];

    var currentIndex = 12;
    var loadmore = false;

    jQuery('.filterby-header span').on('click', function() {
        filterby = [];
        jQuery('.package-filter-options').find('.my-checkbox').prop('checked', false);
        jQuery('.package-filter-options').find('.my-checkbox').removeClass('filterActive');
        jQuery('.package-filter-options').find('.package-filter-option').removeClass('filterActive');
        loadmore = false;
        searchResults(sortby, postcode, filterby);
    });
    if (jQuery('.broadband-online-deals').length > 0) {
        if (jQuery('.bb-input-field input').val() !== '') {
            loadmore = false;
            jQuery('.bb-online-results').html("<div class='bb-loader'><img class='star-empty' src='" + my_data.pluginImageUrl + "loader.gif'/> <h4>Searching the market for the best deals...</h4></div>");
            searchResults(sortby, postcode, filterby);
        }
    }

    jQuery('.bb-sortby span').on('click', function() { 
        if (jQuery(this).hasClass('open')) {
            jQuery(this).removeClass('open');
            jQuery('#sortbyOpen').hide();
            jQuery('.sortby-panel').hide(300);
            jQuery('#sortbyClosed').show();
        } else {
            jQuery(this).addClass('open');
            jQuery('#sortbyOpen').show();
            jQuery('.sortby-panel').show(300);
            jQuery('#sortbyClosed').hide();
        }
    });

        jQuery('.bb-filterby span').on('click', function() {
        if (jQuery(this).hasClass('open')) {
            jQuery(this).removeClass('open');
            jQuery('#filterbyOpen').hide();
            jQuery('.filterby-panel').hide(300);
            jQuery('#filterbyClosed').show();
        } else {
            jQuery(this).addClass('open');
            jQuery('#filterbyOpen').show();
            jQuery('.filterby-panel').show(300);
            jQuery('#filterbyClosed').hide();
        }
    });

    jQuery('.filterbyfilter').on('click', function() {
        if (jQuery(this).hasClass('filterActive')) {
            jQuery(this).removeClass('filterActive');
        } else {
            jQuery(this).closest('.package-filter-options').find('.my-checkbox').removeClass('filterActive');
            jQuery(this).closest('.package-filter-options').find('.my-checkbox').prop('checked', false);
            jQuery(this).addClass('filterActive');
            jQuery(this).prop('checked', true);
        }
        var key = jQuery(this).data('key');
        var value = jQuery(this).val();
        var found = false;
        var index = -1;
        for (var i = 0; i < filterby.length; i++) {
            if (filterby[i].hasOwnProperty(key)) {
                if (filterby[i][key] === value) {
                    index = i;
                    found = true;
                    break;
                } else {
                    filterby[i][key] = value;
                    found = true;
                    break;
                }
            }
        }
        if (!found) {
            filterby.push({[key]: value});
        } else if (index !== -1) {
            filterby.splice(index, 1);
        }

        loadmore = false;
        searchResults(sortby,postcode, filterby);
    });

    jQuery('.filter-alt .package-filter-option').on('click', function() {
        if (jQuery(this).hasClass('filterActive')) {
            jQuery(this).removeClass('filterActive');
        } else {
            jQuery(this).closest('.package-filter-options').find('.package-filter-option').removeClass('filterActive');
            jQuery(this).addClass('filterActive');
        }
        var key = jQuery(this).data('key');
        var value = jQuery(this).data('value');
        var found = false;
        var index = -1;
        for (var i = 0; i < filterby.length; i++) {
            if (filterby[i].hasOwnProperty(key)) {
                if (filterby[i][key] === value) {
                    index = i;
                    found = true;
                    break;
                } else {
                    filterby[i][key] = value;
                    found = true;
                    break;
                }
            }
        }
        if (!found) {
            filterby.push({[key]: value});
        } else if (index !== -1) {
            filterby.splice(index, 1);
        }
        loadmore = false;
        searchResults(sortby,postcode, filterby);
    });

    jQuery('.filterby-submit button').on('click', function() {
        jQuery('.bb-filterby span').click();
    });

    jQuery('.sortby-option').on('click', function() {
        if (jQuery(this).hasClass('optionActive')) {
                jQuery(this).removeClass('optionActive');
                sortby = "";
        } else {
            jQuery('.sortby-option').removeClass('optionActive');
            jQuery(this).addClass('optionActive');
            sortby = jQuery(this).data('value');
            loadmore = false;
            searchResults(sortby,postcode, filterby);
        } 
        jQuery('.bb-sortby span').click();
    });

        jQuery('.filterby-option').on('click', function() {
        if (jQuery(this).hasClass('optionActive')) {
                jQuery(this).removeClass('optionActive');
                sortby = "";
        } else {
            jQuery('.filterby-option').removeClass('optionActive');
            jQuery(this).addClass('optionActive');
            sortby = jQuery(this).data('value');
            loadmore = false;
            searchResults(sortby,postcode, filterby);
        } 
        jQuery('.bb-filterby span').click();
    });

    jQuery('.broadband-online-deals .bb-search-btn').on('click', function() {
        var postcode = jQuery('.bb-input-field input').val();
        loadmore = false;
        searchResults(sortby,postcode, filterby);
    });




    function searchResults(sortby, postcode, filterby) {
        if (loadmore == true) {

        } else {
            if (jQuery('.bb-online-results').length > 0) {
                jQuery('html, body').animate({
                    scrollTop: jQuery('.bb-online-results').offset().top - 120
                }, 'slow');
            }   
        }

        var sort = sortby;

        var currentURL = window.location.href;
        var keyword = 'creditladder';
        var keyword2 = 'f2f-team';
        var keyword3 = 'awin';
    
        if (currentURL.indexOf(keyword) !== -1) {
            // The word "creditladder" is found in the current URL
            //console.log('is true');
            var creditladder = "true";
        } else if (currentURL.indexOf(keyword2) !== -1) {
            // The word "creditladder" is not found in the current URL
            var f2fteam = "true";
        } else if (currentURL.indexOf(keyword3) !== -1 ) {
            var awin = "true";
        } else {
            var creditladder = "false";
            var f2fteam = "false";
            var awin = "false";
        }

        // if url has parameter of userid get the value
        var urlParams = new URLSearchParams(window.location.search);
        var team = urlParams.get('team');

        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: {action: "pl_bb_genie_generate", postcode: postcode, creditladder: creditladder, f2fteam: f2fteam, team: team},
            beforeSend: function() {
                jQuery('.filterby-submit button').hide();
                jQuery('.filterby-submit img').show();
                if (loadmore == true) {
                    jQuery('.bb-online-result-more img').show();
                } else {
                    jQuery('.bb-online-results').html("<div class='bb-loader'><img class='star-empty' src='" + my_data.pluginImageUrl + "loader.gif'/> <h4>Searching the market for the best deals...</h4></div>");
                }
            },
            success: function (data) {
                jQuery('.bb-online-results').html("");
                jQuery('.bb-online-result-more img').hide();
                jQuery('.filterby-submit button').show();
                jQuery('.filterby-submit img').hide();
                jQuery('.desktop').css('display', 'flex');
                //console.log(data);
                data = data.toString();
                data = data.replace(/0+$/, '');


                if (postcode == "") {

                } else {
                
                }


                var sortedData = JSON.parse(data);
                sortedData = Object.values(sortedData.products);
                
                var providerList = [];
                Object.values(sortedData).forEach(function(value) {
                  if (!providerList.includes(value.network.shortName)) {
                    providerList.push(value.network.shortName);
                  }
                });
            


                jQuery('.bb-sortby').css('visibility', 'visible');
                jQuery('.bb-filterby').css('visibility', 'visible');

                // sort sortedData based on outlink p=1 paramter in url
                sortedData.sort(function(a, b) {
                    return a.outlink.split('p=')[1] - b.outlink.split('p=')[1];
                });

                if (sortby !== "") {
                    if (sortby == 'cheaptoexpensive') {
                        sortedData.sort(function(a, b) {
                            return a.monthlyCost - b.monthlyCost;
                        });
                    }
                    if (sortby == 'expensivetocheap') {
                        sortedData.sort(function(a, b) {
                            return b.monthlyCost - a.monthlyCost;
                        });
                    }

                    if (sortby == 'slowtofast') {
                        sortedData.sort(function(a, b) {
                            return a.downloadSpeed - b.downloadSpeed;
                        });
                    }
                    if (sortby == 'fasttoslow') {
                        sortedData.sort(function(a, b) {
                            return b.downloadSpeed - a.downloadSpeed;
                        });
                    }

                    if (sortby == 'lowtohighstars') {
                        sortedData.sort(function(a, b) {
                            return a.rating - b.rating;
                        });
                    }
                    if (sortby == 'highttolowstars') {
                        sortedData.sort(function(a, b) {
                            return b.rating - a.rating;
                        });
                    }

                }

                var package = "";
                var speedfilter = "";
                var broadbandtype = "";
                var provider = "";
                var contract = "";
                var cost = "";
                var calls = "";
                var filters = [];
                if (Object.keys(filterby).length !== 0) {
                    for (let i = 0; i < filterby.length; i++) {
                    jQuery.each(filterby[i], function(key,value) {

                        if (key == 'package') {
                            package = value;
                           filters.push({[key]: value});
                        }
                        if (key == 'speed') {
                            speedfilter = value;
                             filters.push({[key]: value});
                        }
                        if (key == 'broadbandtype') {
                            broadbandtype = value;
                             filters.push({[key]: value});
                        }
                        if (key == 'provider') {
                            provider = value;
                             filters.push({[key]: value});
                        }
                        if (key == 'contract') {
                            contract = value;
                             filters.push({[key]: value});
                        }
                        if (key == 'cost') {
                            cost = value;
                             filters.push({[key]: value});
                        }
                        if (key == 'calls') {
                            call = value;
                             filters.push({[key]: value});
                        }
                  });
                }


                let filterConditions = function(sortedData) { return true; };

                jQuery.each(filters, function(index, filter) {
                  for (var key in filter) {
                    if (key == 'package') {
                      if (filter[key] == "true") {
                        let oldFilter = filterConditions;
                        filterConditions = function(sortedData) {
                          return oldFilter(sortedData) && sortedData.tv == true;
                        };
                      }
                      if (filter[key] == "false") {
                        let oldFilter = filterConditions;
                        filterConditions = function(sortedData) {
                          return oldFilter(sortedData) && sortedData.tv == false;
                        };
                      }
                    }
                    
                    if (key == 'speed') {
                          let oldFilter = filterConditions;
                          filterConditions = function(sortedData) {
                            let splitValue = filter[key].split("-");
                            return oldFilter(sortedData) && sortedData.downloadSpeed > splitValue[0] && sortedData.downloadSpeed <= splitValue[1];
                          };
                      }
                      if (key == 'broadbandtype') {
                        if (filter[key] == "true") {
                          let oldFilter = filterConditions;
                          filterConditions = function(sortedData) {
                            return oldFilter(sortedData) && sortedData.fibre == true;
                          };
                        }
                        if (filter[key] == "false") {
                          let oldFilter = filterConditions;
                          filterConditions = function(sortedData) {
                            return oldFilter(sortedData) && sortedData.fibre == false;
                          };
                        }
                      }
                      if (key == 'provider') {
                        let oldFilter = filterConditions;
                        filterConditions = function(sortedData) {
                          return oldFilter(sortedData) && sortedData.network.shortName == filter[key];
                        };
                    }
                    if (key == 'calls') {
                        let oldFilter = filterConditions;
                        filterConditions = function(sortedData) {
                          return oldFilter(sortedData) && sortedData.freeCalls == filter[key];
                        };
                    }
                    if (key == 'contract') {
                        //console.log(filter[key]);
                        let oldFilter = filterConditions;
                        filterConditions = function(sortedData) {
                          return oldFilter(sortedData) && sortedData.contractLength == filter[key];
                        };
                    }
                    if (key == 'cost') {
                        let oldFilter = filterConditions;
                        filterConditions = function(sortedData) {
                          let splitValue = filter[key].split("-");
                          return oldFilter(sortedData) && sortedData.monthlyCost > splitValue[0] && sortedData.monthlyCost <= splitValue[1];
                        };
                    }
                  }
                });
                
                sortedData = sortedData.filter(filterConditions);

                }

                jQuery('#currentIndex').html(currentIndex);
                jQuery('#totaldeals').html(sortedData.length);

                if (currentIndex <= sortedData.length) {
                    sortedData = sortedData.slice(0, currentIndex);
                    jQuery('.bb-online-result-more').css('display', 'flex');
                } else {
                    jQuery('.bb-online-result-more').hide();
                }

                jQuery('.filterby-submit button span').html('(' + sortedData.length + ')');



                jQuery('.bb-online-results').html("");
                for (let productId in sortedData) { 
                    let product = sortedData[productId];

                    var speed;
                    var speedVar;
                    if (product.downloadSpeed > 999999) {
                        speed = product.downloadSpeed / 1000000;
                        speedVar = "Gb";
                        speed = Math.round(speed);
                    } else {
                        speed = product.downloadSpeed / 1000;
                        speedVar = "Mb";
                        speed = Math.round(speed);
                    }

                    var rating = "";

                    if (product.network.shortName == "Virgin Media") {
                        product.rating = 5;
                    } else {
                        product.rating = 4;
                    } 

                    rating = product.rating;

                    var stars = "";
                    for (var i = 1; i <= 5; i++) {
                        if (i > rating) {
                            stars += "<img class='star-empty' src='" + my_data.pluginImageUrl + "star_rate_empty.svg'/>";
                        } else {
                            stars += "<img class='star-filled' src='" + my_data.pluginImageUrl + "star_rate_filled.svg'/>";
                        }
                    }
                    
                    var icons = "";

                    if (product.broadband == true) {
                        icons += "<div class='package-icon'><img src='" + my_data.pluginImageUrl + "wifi.png'/></div>"
                    }
                    if (product.tv == true) {
                        icons += "<div class='package-icon'><img src='" + my_data.pluginImageUrl + "television.png'/></div>"
                    }
                    if (product.landLine) {
                        icons += "<div class='package-icon'><img src='" + my_data.pluginImageUrl + "phone-call.png'/></div>"
                    }

                    var displayReward = "";
                    var cpiNotice = "";

                    if (product.offerText !== null) {
                        // split offers text by . and loop through each one and add a <p> tag to each one
                        var offerText = product.offerText.split('.');
                        for (var i = 0; i < offerText.length; i++) {
                            // remove any whitespace at start of string
                            offerText[i] = offerText[i].replace(/^\s+/g, '');
                            // if offerText[i] starts with £ then add a <p> tag to it
                            if (offerText[i].startsWith('£')) {
                                // offerText[i] contains 'reward'
                                if (offerText[i].includes('Reward')) {
                                    displayReward = offerText[i];
                                }
                                if (offerText[i].includes('Gift Card')) {
                                    displayReward = offerText[i];
                                }
                            }
                            if (offerText[i].includes('CPI')) {
                                cpiNotice = "Prices may change during contract";
                            }
                        }
                    }
                    
                    // if outlink contains p=1 only not p=11 or p=111

                    if (product.outlink.endsWith('p=1')) {
                        displayReward = "Switcheroo Recommended Deal";
                    }

                    

                        jQuery('.bb-online-results').append(`<div class='bb-online-deal basic-deal'>
                        <div class='online-deal-inner'>
                            ${displayReward ? `<div class='deal-reward'><h4>${displayReward}</h4></div>` : ''}
                            <div class='deal-header'>
                                <div class='deal-header-logo'>
                                    <div class='deal-header-row'>
                                        <img src='${product.network.logo}'/>
                                        <p>${product.name}</p>
                                        <div class='deal-stars'>
                                            ${stars}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div class='deal-usps'>
                            <div class='deal-usp-speed'>
                                <p>${speed} ${speedVar}</p>
                                <span>Average Speed*</span>
                            </div>
                            <div class='deal-usp-contract'>
                                <p>${product.contractLength}</p>
                                <span>Month Contract</span>
                            </div>
                            <div class='deal-usp-setup'>
                                <p>£${product.setupFee}</p>
                                <span>Setup Cost</span>
                            </div>
                            <div class='deal-usp-setup'>
                            <p>£${product.monthlyCost}</p>
                            <span>month</span>
                        </div>
                            <a target='_blank' href='${product.outlink}' class='deal-usp-cta'>
                                <p>See Deal</p>
                                <img src='` + my_data.pluginImageUrl + 'right-arrow.png' + `'/>
                            </a>
                        </div>
        
                        <div class='deal-footer'>
                            <span>${cpiNotice}</span>
                        </div>
                        </div>
                    </div>`);

                }

                jQuery('.close-deal-new').on('click', function() {
                    jQuery(this).closest('.about-deal').toggleClass('hidden');
                });
            
                jQuery('.deal-more span').on('click', function() {
                    jQuery(this).siblings('.about-deal').toggleClass('hidden');
                });

                jQuery('.deal-more span').on('touchstart', function() {
                    jQuery(this).siblings('.about-deal').toggleClass('hidden');
                });

                jQuery('.deal-more span').on('touchend', function() {
                    jQuery(this).siblings('.about-deal').toggleClass('hidden');
                });

                jQuery('.deal-more span').on('touchmove', function() {
                    jQuery(this).siblings('.about-deal').toggleClass('hidden');
                });
                  
                
            }

        });

    }

    jQuery('.bb-online-result-more').on('click', function() {
        currentIndex += 12;
        loadmore = true;
        searchResults(sortby, postcode, filterby, currentIndex);
    });
    
    jQuery('#lp-search').on('click', function() {
        var postcodelp = jQuery('#lp-postcode').val();
        //console.log(postcodelp);
        var pageUrl = window.location.href;
        pageUrl += "/compare-broadband-online-deals";
        window.location.href = pageUrl + '?postcode=' + postcodelp;
    });

    jQuery('#lp-search-desk').on('click', function() {
        var postcodelp = jQuery('#lp-postcode-desk').val();
        //console.log(postcodelp);
        var pageUrl = window.location.href;
        pageUrl += "/compare-broadband-online-deals";
        window.location.href = pageUrl + '?postcode=' + postcodelp;
    });


    var urlParams = new URLSearchParams(window.location.search);
    var packageType = urlParams.get('packagetype');
    var broadbandtype = urlParams.get('broadbandtype');
    var sortbytype = urlParams.get('sortby');
    var contractlength = urlParams.get('contractlength');
  
    if (packageType === 'broadband-only') {
        var nextInputElement = jQuery('input.filterbyfilter[data-key="package"][value="false"]');
          nextInputElement.click();
          var key = 'package';
          var value = 'false';
      
          var obj = {};
          obj[key] = value;
          filterby = [];
          if (filterby.length === 0) {
            filterby.push(obj);
          }
          searchResults(sortby,postcode, filterby);
    }
    if (packageType === 'broadband-tv') {
        var nextInputElement = jQuery('input.filterbyfilter[data-key="package"][value="true"]');
        nextInputElement.click();
        var key = 'package';
        var value = 'true';
    
        var obj = {};
        obj[key] = value;
        filterby = [];
        if (filterby.length === 0) {
          filterby.push(obj);
        }
        searchResults(sortby,postcode, filterby);
    }

    if (broadbandtype === 'fibre-broadband') {
        //console.log('here');
        var nextInputElement = jQuery('input.filterbyfilter[data-key="broadbandtype"][value="true"]');
          nextInputElement.click();
          var key = 'broadbandtype';
          var value = 'true';
      
          var obj = {};
          obj[key] = value;
          //filterby = [];
          if (filterby.length === 0) {
            filterby.push(obj);
          }
          searchResults(sortby,postcode, filterby);
    }
    if (broadbandtype === 'standard-broadband') {
        var nextInputElement = jQuery('input.filterbyfilter[data-key="broadbandtype"][value="false"]');
        nextInputElement.click();
        var key = 'broadbandtype';
        var value = 'false';
    
        var obj = {};
        obj[key] = value;
        //filterby = [];
        if (filterby.length === 0) {
          filterby.push(obj);
        }
        searchResults(sortby,postcode, filterby);
    }

    if (sortbytype === 'fasttoslow') {
        sortby = 'fasttoslow';
        searchResults(sortby,postcode, filterby);
    }

    if (sortbytype === 'cheaptoexpensive') {
        sortby = 'cheaptoexpensive';
        searchResults(sortby,postcode, filterby);
    }

    if (contractlength === '1') {
        var nextInputElement = jQuery('.package-filter-option[data-key="contract"][value="1"]');
        nextInputElement.click();
        var key = 'contract';
        var value = '1';
    
        var obj = {};
        obj[key] = value;
        if (filterby.length === 0) {
          filterby.push(obj);
        }
        searchResults(sortby,postcode, filterby);
    }

    // if class is present on page then run the function
    if (jQuery('.broadband-online-deals').length > 0) {
        searchResults(sortby, postcode, filterby);
    }

});


